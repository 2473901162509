import {
  AppManifestBuilder,
  Experiments,
  FlowAPI,
  FlowAppDefinitionId,
  FlowEditorSDK,
  FlowPlatformOptions,
  TFunction,
} from '@wix/yoshi-flow-editor';
import {
  withMembersArea,
  MA_APP_IDS,
  registerMembersAreaApps,
} from '@wix/members-area-integration-kit';

let sdk: FlowEditorSDK;
let _translate: TFunction;
let experiments: Experiments;

const getFileShareEditorApi = () => ({
  editorReady(
    editorSDK: FlowEditorSDK,
    _appToken: FlowAppDefinitionId,
    options: FlowPlatformOptions,
    flowAPI: FlowAPI,
  ) {
    console.log('getFileShareEditorApi - editorReady');

  },
  exports: () => ({}),
});

export const { editorReady, exports, getAppManifest } = withMembersArea(
  getFileShareEditorApi(),
  {
    membersAreaApps: [
      MA_APP_IDS.ALL_MEMBERS,
      MA_APP_IDS.NOTIFICATIONS,
      MA_APP_IDS.FILE_SHARE,
    ],
  },
);
